.bg-image {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-image .mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #fff;
}

.bg-image .mask .text {
  height: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.bg-image .mask .text p {
  margin: 0;
}

.printable {
  page-break-before: always;
}

.ant-comment-actions li {
  width: 100%;
}

::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  overflow: visible;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #5c5f5f;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9c9ca0;
}



.glowing::before {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(45deg, #e8f74d, #ff6600d9, #00ff66, #13ff13, #ad27ad, #bd2681, #6512b9, #ff3300de, #5aabde);
  background-size: 400%;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: -1;
  animation: glower 20s linear infinite;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.overlay-custom-class-name {
  z-index: 1001;
}

.badge {
  box-shadow: none !important;
}

.text-bold-high {
  font-weight: 900 !important;
}

.text-bold-medium {
  font-weight: 400 !important;
}

body {
  background: #f5f5f5;
}

.card {
  box-shadow: 0px 10px 15px -13px rgba(0, 0, 0, 0.1) !important;
}

.shadow {
  box-shadow: 0px 10px 15px -13px rgba(0, 0, 0, 0.1) !important;
}

.bg-white-transaparent {
  background: rgba(255, 255, 255, 1) !important;
}


.sepia {
  background: #edca82 !important;
}

.teal {
  background: #097779 !important;
}

.tan {
  background: #ecc19c !important;
}

.deep-turquiose {
  background: #1e847f !important;
}

.mustard {
  background: #e5ac28 !important;
}

.forest-green {
  background: #3abb35 !important;
}

.turquiose {
  background: #79cbb8 !important;
}

.violet {
  background: #500472 !important;
}

.fushsia {
  background: #d13cc4 !important;
}

.tan-deep {
  background: #ecc19c !important;
}

.brown {
  background: #322514 !important;
}


.h-scrolling-wrapper {
  /* display: flex; */
  /* flex-wrap: nowrap; */
  overflow-x: auto;
}

.h-scrolling-item {
  flex: 0 0 auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.h-scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrolling-wrapper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}



.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}


label,p{
  font-weight: 400;
}
